import CybrPage from "./maintenance.svg"
import "./App.css";

function App() {
  return (
    <div className="App" class="center-svg">
      <header className="App-header">
        <img src={CybrPage}/>
      </header>
    </div>
  );
}

export default App;